<template>

  <b-row class="filters-container">
    <b-col cols="8">
      <label for="patient">Paciente</label>
      <div class="search-container">
        <Search class="icon" />
        <b-form-input
          type="text"
          v-model="filters.patient"
          debounce="500"
          placeholder="Buscar paciente"
        />
      </div>
    </b-col>

    <b-col cols="2">
      <label for="guide-number">Nº da guia</label>
      <b-form-input
        id="guideNumber"
        type="text"
        v-model="filters.guideNumber"
        placeholder="Pesquisar"
        debounce="500"
      />
    </b-col>

    <b-col cols="2" v-if="SusBatchType.BATCH_TYPE_APAC === type">
      <label for="apac_authorization_number">Código APAC</label>
      <b-form-input
        id="apac_authorization_number"
        type="text"
        v-model="filters.apac_authorization_number"
        placeholder="Pesquisar"
        debounce="500"
      />
    </b-col>

  </b-row>
</template>

<script>
import Search from '@/assets/icons/search.svg'
import moment from 'moment';
import { SusBatchType } from '@/modules/sus/manageGuides/utils/statuses'

export default {
  components: {
    Search,
  },
  props: {
    type: String,
  },
  data() {
    return {
      SusBatchType,
      filters: {
        patient: null,
        guideNumber: null,
        guideType: null,
        status: []
      },
      status: [
        'Todos',
        'Pago',
        'Pago Parcialmente',
        'Glosada',
        'Em recurso',
        'Enviado',
        'Aguardando pagamento'
      ],
      langDatePicker: {
        formatLocale: {
          months: moment.months(),
          monthsShort: moment.monthsShort(),
        },
      },
    }
  },
  methods: {
    changeSelectedStatus(newValue) {
      this.filters.status = newValue
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit('change', newValue)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-container {
  border-top: 1px solid #C6CEEB;
  margin: 20px 0;
  padding-top: 25px;

  label {
    font-weight: 700;
  }

  .search-container {
    display: flex;
    flex-direction: row;
    position: relative;

    .icon {
      position: absolute;
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      height: 100%;
      margin-left: 10px;
    }

    input {
      padding-left: 40px !important;
    }
  }
}
</style>